<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :options.sync="options"
    :server-items-length="totalItems"
    :loading="loading"
    sort-by="name"
    must-sort
    class=""
    calculate-widths
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{ $t('Types of activities') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="options.search"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
          class="shrink mr-5"
        ></v-text-field>
        <v-btn
            :to="{ name: 'ActivityTypesCreate' }"
            fab
            dark
            color="primary"
        >
            <v-icon
            dark
            >
            mdi-plus
            </v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{item}" >
        <v-btn :to="{name: 'ActivityTypesEdit', query: { id: item.id, page: options.page }}" plain text icon class="mr-2">
            <v-hover v-slot="{ hover }">
            <v-icon :color="hover? 'green': 'grey'"
            >
            mdi-pencil
            </v-icon>
          </v-hover>
        </v-btn>

        <v-btn :to="{name: 'ActivityTypesDelete', query: { id: item.id,name:item.name, page: options.page }}" plain text icon class="">
            <v-hover v-slot="{ hover }">
              <v-icon  :color="hover? 'primary': 'grey'"
              >
              mdi-delete
              </v-icon>
            </v-hover>
        </v-btn>
    </template>
    <template v-slot:item.color="{item}" >
      <v-avatar
        :color="item.color"
        size="25"
      ></v-avatar>
    </template>
    <template v-slot:no-data>
      {{ $t('No items found') }}
    </template>
  </v-data-table>
</template>

<script>
    export default {
        data(){
            return{
                search: '',
                totalItems: 0,
                options: {},
                loading: true,
                headers: [
                    { text: this.$t('Name'), align: 'start', value: 'name'},
                    { text: this.$t('Color'), value: 'color', sortable: false },
                    { text: this.$t('Description'), value: 'description', sortable: false },
                    { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false },
                ],
                items: []
            }
        },

        watch: {
            options: {
                handler () {
                  this.fetchItems()
                },
                deep: true,
            }
        },
        mounted ()
        {
            if(this.$route.query.page > 0){
              this.options.page = parseInt(this.$route.query.page);
            }

            this.fetchItems();
        },

        methods: {
            fetchItems()
            {
              this.$router.replace({ query: { page: this.options.page } }).catch(()=>{});

              let uri = this.$urlPrefix + '/ActivityType/CountAll';
              let uri2 = this.$urlPrefix + '/ActivityType/GetList?start='+ (this.options.page -1) * this.options.itemsPerPage+'&count='+this.options.itemsPerPage;
              if(this.options.search != undefined && this.options.search != ""){
                uri2 += '&search='+this.options.search;
              }
              if(this.options.sortBy){
                uri2 += '&sort='+this.options.sortBy[0];
              }
               if(this.options.sortDesc){
                uri2 += '&sortDescending='+this.options.sortDesc[0];
              }
              this.axios.get(uri).then((response) => {
                  this.totalItems = response.data;
                this.axios.get(uri2).then((response) => {
                    this.items = response.data;
                    this.loading = false;
                });
              });
            },
        }
    }
</script>
